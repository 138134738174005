import React from 'react';

export default function Services() {
    const services = [
        { id: 1, icon: 'bi-rocket2', title: 'Web design' },
        { id: 2, icon: 'bi-target-arrow', title: 'Website development' },
        { id: 3, icon: 'bi-graph-bar', title: 'Wordpress maintenance' },
        // { id: 4, icon: 'bi-measurement', title: 'Content Strategy' },
        // { id: 5, icon: 'bi-paper-plane', title: 'Email Marketing' },
        // { id: 6, icon: 'bi-tie', title: 'Sales Promotion' } 
    ];
    return (
        <section id="services" className="services">
            <div className="container">
                <div className="row">
                    <h2 className="section-heading">Our Services...</h2>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    {services.map(item => {
                        return (
                            <div key={item.id} className="col-md-4 col-sm-6 icon-box-wrapper">
                                <div className="icon-box">
                                    <div className="icon-left"><span className={'bi ' +  item.icon}></span></div>
                                    <div className="icon-content">
                                        <h4 className="title">{item.title}</h4>
                                        <p>Lorem ipsum dolor sit amet, mel vis autem habemus recteque no. Ut per tota erant.</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </section>
    ); 
}