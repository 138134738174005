import React from 'react';

// Import images of the blogs
import blog1 from '../assets/images/blog/blog1.jpg';
import blog2 from '../assets/images/blog/blog2.jpg';
import blog3 from '../assets/images/blog/blog3.jpg';

export default function Blog() {
    const blogs = [
        {id: 1, blog: blog1, date: '17 Oct, 2017'}, 
        {id: 2, blog: blog2, date: '18 Oct, 2017'},
        {id: 3, blog: blog3, date: '20 Oct, 2017'}
    ];

    return (
        <div id="blog" className="even_div">
            <div className="container">
                <h2>From the Blog</h2>

                <div className="blog_wrapp">
                    {blogs.map(item => {
                        return (
                            <div key={item.id} className="col-md-4 col-sm-4 col-xs-12 wow fadeIn animated">
                                <div className="blog_block">
                                    <div className="blog_img">
                                        <div>
                                            <img className="img-responsive" alt="" src={item.blog}/>
                                            <div className="blog_img_hoverlay"></div>
                                            <div className="blog_img_hoverlay_icon"><i className="fa fa-eye"></i></div>
                                        </div>
                                        <span><i className="fa fa-calendar"></i>{item.date}</span>
                                    </div>
                                    <div className="blog_info">
                                        <h3><a href="/"> Pellentesque habitant morbi </a></h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin venenatis magna sit amet eros lobortis, quis maximus ex consequat.</p>
                                        <a href="/" className="btn btn_glob">Read More</a>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>

            </div>
        </div>
    );
}