import React from 'react';

// Logos for Framework
import AngularLogo from '../assets/images/brands/angular.png';
import ReactLogo from '../assets/images/brands/react.png';
import VueLogo from '../assets/images/brands/vue.png';
import LaravelLogo from '../assets/images/brands/laravel.png';
import WordpressLogo from '../assets/images/brands/wordpress.png';
import CodeigniterLogo from '../assets/images/brands/codeigniter.png';
import NodeLogo from '../assets/images/brands/node.png';

export default function Header() {
    const links = [
        {id: 1, name: 'Services', href: '#services'},
        {id: 2, name: 'Portfolio', href: '#services'},
        {id: 3, name: 'Testimonial', href: '#testimonial'},
        {id: 4, name: 'Team', href: '#team'},
        {id: 5, name: 'Blog', href: '#blog'},
        {id: 6, name: 'Pricing', href: '#pricing'},
        {id: 7, name: 'Contact Us', href: '#newsletter'}
    ];

    const logos = [
        {id: 1, logo: AngularLogo, name: 'Angular', color: '#E23237'}, 
        {id: 2, logo: ReactLogo, name: 'React', color: '#61DBFB'},
        {id: 3, logo: VueLogo, name: 'Vue', color: '#3FB984'}, 
        {id: 4, logo: LaravelLogo, name: 'Laravel', color: '#C12419'}, 
        {id: 5, logo: WordpressLogo, name: 'Wordpress', color: '#247EA8'}, 
        {id: 6, logo: CodeigniterLogo, name: 'Codeigniter', color: '#EE4323'}, 
        {id: 7, logo: NodeLogo, name: 'Node', color: '#4F9541'}
    ];
    return (
        <div id="header">
            <div className="mainnav">
                <div className="container">
                    <div className="row">
                        <div className="logo col-md-3 col-sm-8 col-xs-8">
                            <a className="text_logo" href="#header">Tiger Developer</a>
                        </div>
                        <div className="col-md-9 col-sm-4 col-xs-4">
                            <a id="offcanvas_toggler" className="visible-sm visible-xs" href="/"><i className="fa fa-bars"></i></a>
                            <div className="main_navigation">
                                <ul>
                                    { 
                                        links.map(item => {
                                            return <li key={item.id}><a className="h_menu" href={item.href}>{item.name}</a></li>
                                        }) 
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="header_content">
                    <h1>Tiger Developer</h1>
                    <h4>Your Professional Software Development Partner</h4>
                    <a className="get_started_btn" href="#newsletter">Contact Us</a>
                </div>
                <div className="brand_slider_wrapp">
                    <div id="brand_slider" className="brand_slider hidden-buttons">
                        <div className="slider-items slider-width-col6 owl-carousel owl-theme">
                            {logos.map(item => {
                                return (
                                    <div key={item.id} className="item">
                                        <div className="col-item">
                                            <div className="item-inner">
                                                <div className="product-wrapper">
                                                    <div className="thumb-wrapper">
                                                        <a href="/"><img className="img-responsive" src={item.logo} alt="Brand imgae" style={{height: '60px'}}/></a>
                                                        <p style={{color: item.color}}>{item.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}