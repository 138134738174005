import React from 'react';

function Contact() {
    return (
        <div id="newsletter" className="contact">
            <div className="container">
                <div className="section-title text-center">
                    <h2 className="h1">Get in Touch</h2>
                    <span className="title-underline"><i className="fa fa-circle-thin"></i></span>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores similique dolor ab inventore, veritatis deserunt suscipit vitae minima.</p>
                </div>
                <div className="contact-form">
                    <div className="col-md-8 col-md-offset-2">
                        <form>
                            <input type="hidden" name="form-name" value="form 1"/>
                            <div className="col-md-6">
                                <input className="form-control" type="text" placeholder="Your Name" required=""/>
                            </div>
                            <div className="col-md-6">
                                <input className="form-control" type="email" placeholder="Your E-Mail" required=""/>
                            </div>
                            <div className="col-md-12">
                                <input className="form-control" type="text" placeholder="Subject" required=""/>
                            </div>
                            <div className="col-md-12">
                                <textarea className="form-control" placeholder="Your Message" required=""></textarea>
                            </div>
                            <div className="col-md-12">
                                <div className="send-butt">
                                    <button>Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;