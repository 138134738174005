import React from 'react';

export default function Counters() {
    const counters = [
        { id: 1, icon: 'bi-alarm-clock', count: 2265, title: 'Hours of work' },
        { id: 2, icon: 'bi-emo-smile', count: 37, title: 'Satisfied clients' },
        { id: 3, icon: 'bi-like', count: 81, title: 'Projects Completed' },
        { id: 4, icon: 'bi-cup', count: 10, title: 'Awards Won' }
    ];
    return (
        <div className="counters mt-20">
            <div className="container">
                <div className="row">
                    {counters.map(item => {
                        return (
                            <div key={item.id}  className="col-md-3 col-sm-6">
                                <div className="counter"> <i className={'bi ' +  item.icon}></i>
                                    <div className="number animateNumber" data-num={item.count}> <span>{item.count}</span></div>
                                    <p>{item.title}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}