import React from 'react';

function Sidebar() {
    return (
        <div className="offcanvas_menu">
            <a href="/" className="close_offcanvas"><i className="fa fa-remove"></i></a>
            <div className="offcanvas_inner">
                <div className="sp_module">
                    <h3 className="sp_module_title">Startup-Pro</h3>
                    <div className="sp_module_content">
                        <ul className="nav menu">
                            <li><a className="h_menu" href="#services">Services</a></li>
                            <li><a className="h_menu" href="#portfolio">Portfolio</a></li>
                            <li><a className="h_menu" href="#testimonial">Testimonial</a></li>
                            <li><a className="h_menu" href="#team">Team</a></li>
                            <li><a className="h_menu" href="#blog">Blog</a></li>
                            <li><a className="h_menu" href="#pricing">Pricing</a></li>
                            <li><a className="h_menu" href="#newsletter"> Contact Us </a> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;