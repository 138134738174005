import React from 'react'

import feruzPhoto from '../assets/images/team/feruz.png'
import berniePhoto from '../assets/images/team/bernie.jpg'
import member2 from '../assets/images/team/anna_shaw.jpg'
import member3 from '../assets/images/team/leon_thompson.jpg'
import member4 from '../assets/images/team/sara_gomes.jpg'
import member5 from '../assets/images/team/peter_decosta.jpg'
import member6 from '../assets/images/team/ashley_moody.jpg'
import member7 from '../assets/images/team/jerry_mack.jpg'
// import heZhengPhoto from '../assets/images/team/he_zheng.jpg';
import mingyangMaPhoto from '../assets/images/team/mingyanMa.jpg'

function Team() {
  const team_members = [
    {
      id: 1,
      member: member3,
      name: 'Van Hau Duong',
      title: 'Full stack Developer',
      description: 'email : vanhauduong@gmail.com'
    },
    {
      id: 7,
      member: member7,
      name: 'Sang',
      title: 'Full stack Developer',
      description: 'email : sangpdev94@gmail.com'
    },
    {
      id: 4,
      member: member5,
      name: 'Vorakham',
      title: 'Front end Developer',
      description: 'email : vorakham@gmail.com'
    },
    // {id: 6, member: mingyangMaPhoto, name: 'MingYan Ma', title: 'Full stack Developer', description: 'email : mamingyan3839@163.com'},
    // {id: 3, member: heZhengPhoto, name: 'He Zheng', title: 'Full stack Developer', description: 'email : zheng1227vip@163.com', email : 'zheng12vip@163.com'},
    {
      id: 5,
      member: feruzPhoto,
      name: 'Feruz Alam',
      title: 'Wordpress Developer',
      description: 'email : feruz.alam@gmail.com'
    },
    {
      id: 2,
      member: member5,
      name: 'Bernie',
      title: 'Full stack Developer',
      description: 'email : berniedev007@gmail.com'
    }
  ]

  return (
    <div id='team'>
      <div className='container'>
        <h2>Meet Our Team</h2>
        <div className='team_slider_wrapp'>
          <div id='team_slider' className='hidden-buttons'>
            <div className='slider-items slider-width-col6 owl-carousel owl-theme'>
              {team_members.map((item, index) => {
                if (index % 2 === 1) {
                  return (
                    <div key={item.id} className='item'>
                      <div className='col-item'>
                        <div className='item-inner'>
                          <div className='product-wrapper'>
                            <div className='thumb-wrapper'>
                              <div className='tmember_image'>
                                <img
                                  className='img-responsive'
                                  src={item.member}
                                  alt={item.name}
                                />
                              </div>
                              <div className='text_block'>
                                <h3 className='tmember_name'>{item.name}</h3>
                                <h4 className='tmember_info'>{item.title}</h4>
                                <p>
                                  {item.description}
                                  <br />
                                  {item.email ? 'email : ' + item.email : ''}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                } else {
                  return (
                    <div key={item.id} className='item'>
                      <div className='col-item'>
                        <div className='item-inner'>
                          <div className='product-wrapper'>
                            <div className='thumb-wrapper'>
                              <div className='text_block'>
                                <h3 className='tmember_name'>{item.name}</h3>
                                <h4 className='tmember_info'>{item.title}</h4>
                                <p>
                                  {item.description}
                                  <br />
                                  {item.email ? 'email : ' + item.email : ''}
                                </p>
                              </div>
                              <div className='tmember_image'>
                                <img
                                  className='img-responsive'
                                  src={item.member}
                                  alt={item.name}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Team
