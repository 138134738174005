import React from 'react';

export default function Footer() {
    const current_year = new Date().getFullYear();
    const social_links = [
        {id: 1, type: 'facebook', link: '#'},
        {id: 2, type: 'twitter', link: '#'},
        {id: 3, type: 'linkedin', link: '#'},
        {id: 4, type: 'pinterest-p', link: '#'},
    ];
    return (
        <div id="footer">
            <div className="container">
                <div className="col-lg-6 col-md-6 col-sm-8 col-xs-8 coppyright"> © {current_year}. All Rights Reserved. </div>
                <div className="social col-lg-6 col-md-6 col-sm-4 col-xs-4">
                    <ul className="social_links">
                        { 
                            social_links.map(item => {
                                return <li key={item.id}><a href={item.link}><i className={"fa fa-" + item.type + ""}></i></a></li>
                            }) 
                        }
                    </ul>
                </div>
            </div>
        </div>
    );
}