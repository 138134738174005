import React from 'react';

import testimonial1 from '../assets/images/testimonial/testimonials1.jpg';
import testimonial2 from '../assets/images/testimonial/testimonials2.jpg';
import testimonial3 from '../assets/images/testimonial/testimonials3.jpg';
import testimonial4 from '../assets/images/testimonial/testimonials4.jpg';

function Testimonial() {

    const testimonialConst = [
        {id: 1, testimonial: testimonial1, author: 'Mark Twin', title: 'Digital marketing Manager', quote: 'I highly recommend him for any development project that he accepts. He was extremely responsive and knowledgable about our needs. He worked quickly and clearly explained (with examples and/or screen shots) any issues or questions we had throughout the project. Having worked with many developers over the last 25 years, I can say that he presented himself and performed as a true professional.'}, 
        {id: 2, testimonial: testimonial3, author: 'Richard Engelson', title: 'Digital marketing Manager', quote: 'He has proven himself to be very useful when it comes to difficult to use CMSs. He also provides excellent communication.'},
        {id: 3, testimonial: testimonial4, author: 'Ashley ', title: 'Technical Project Manager', quote: 'He was a big help. We had a strange issue on our wordpress site and he was able to help us narrow down what the problem was.'}, 
        {id: 4, testimonial: testimonial1, author: 'David Johnson', title: 'Financial Manager', quote: 'He was very timely, knowledgable, fixed my problem rapidly, and communicated very well and professionally. The problem was not completely straightforward, but he understood the details of my situation, and moved rapidly to figure it all out. I will look for him again.'}, 
        {id: 5, testimonial: testimonial2, author: 'Mary Anne Sacdalan', title: 'Financial Manager', quote: 'He completed the project in a timely manner. I have worked with him with another project too and both have had great quality of work. He is professional, quick and goes above and beyond. I met my deadline with a very quick turnaround.'}, 
        {id: 6, testimonial: testimonial3, author: 'John Matt', title: 'Digital marketing Manager', quote: 'He shows great patience and skill in the toughest situations. He comes thru in the clutch! he is super knowledgeable in Personify and WordPress CMS.'},
        {id: 7, testimonial: testimonial4, author: 'Kyle', title: 'Postionning & Profit Strategist', quote: 'He was great~! He completed the work quickly and on time! Will definitely recommend him.'},
        {id: 8, testimonial: testimonial1, author: 'Stephen Khal', title: 'CEO', quote: 'He was a great asset with great communication; I\'m keeping his contact info for sure!'}, 
    ];

    return (
        <section id="testimonial" className="testimonials-wrapper">
            <div className="container">
                <div className="row">
                    <h2 className="section-heading">Client Testimonials...</h2>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div id="testimonials" className="brand_slider hidden-buttons">
                        <div className="slider-items slider-width-col6 owl-carousel owl-theme">
                            {testimonialConst.map(item => {
                                return (
                                    <div key={item.id} className="item">
                                        <div className="col-item">
                                            <div className="item-inner">
                                                <div className="testimonial">
                                                    <h4 className="author">{item.author}</h4>
                                                    <h6 className="title">{item.title}</h6>
                                                    <div className="content thumb"><img className="img-circle" alt="" src={item.testimonial}/></div>
                                                    <p><em>{item.quote}</em></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default Testimonial;