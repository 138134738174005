import React from 'react'
import { Helmet } from 'react-helmet'
import './App.css'
import '../src/assets/style/css/animate.css'
import '../src/assets/style/css/bicon.min.css'
import '../src/assets/style/css/bootstrap.css'
import '../src/assets/style/css/font-awesome.css'
import '../src/assets/style/css/nouislider.css'
import '../src/assets/style/css/owl.carousel.css'
import '../src/assets/style/css/owl.theme.css'
import '../src/assets/style/css/reset.css'
import '../src/assets/style/css/style.css'

import Header from './components/header'
import Services from './components/services'
import Counter from './components/counter'
import Portfolio from './components/portfolio'
import Testimonial from './components/testimonial'
import Team from './components/team'
import Blog from './components/blog'
import Pricing from './components/pricing'
import Contact from './components/contact'
import Footer from './components/footer'
import Sidebar from './components/sidebar'

function App() {
  return (
    <div className='App'>
      <Helmet>
        <title>Tiger Developer</title>
        <meta charSet='utf-8' />
        <meta name='description' content='Tiger Developer' />
        <meta name='og:title' content='Tiger Developer' />
        <meta name='og:description' content='Tiger Developer' />
      </Helmet>
      <Header />
      <div className='content_wrapper'>
        <Services />
        <Counter />
        <Portfolio />
        <Testimonial />
        <Team />
        <Blog />
        {/* <Pricing/> */}
        <Contact />
        <Footer />
      </div>
      <Sidebar />
      <div className='offcanvas_overlay'></div>
    </div>
  )
}

export default App
