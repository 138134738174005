import React, { useState } from 'react';
import Modal from 'react-modal';
import '../assets/style/css/custom.css';

// Import Portfolio image for the wordpress
import portfolio_W1 from '../assets/images/portfolio/wordpress/main/cakaliko.kinsta.cloud.png';
import portfolio_W2 from '../assets/images/portfolio/wordpress/main/isuniversity.org.png';
import portfolio_W3 from '../assets/images/portfolio/wordpress/main/skymarkrefuelers.com.png';
import portfolio_W4 from '../assets/images/portfolio/wordpress/main/trescanorthend.com.png';
import portfolio_W5 from '../assets/images/portfolio/wordpress/main/melroseyouthfootball.com.png';
import portfolio_W6 from '../assets/images/portfolio/wordpress/main/lowellacademy.com.png';

import portfolio_cms1 from '../assets/images/portfolio/cms/spartasystems.png';
import portfolio_cms2 from '../assets/images/portfolio/cms/smoworks.png';
import portfolio_cms3 from '../assets/images/portfolio/cms/wocnext.png';
import portfolio_cms4 from '../assets/images/portfolio/cms/trihydro.png';

import portfolio_react1 from '../assets/images/portfolio/react/djangosoft.png';
import portfolio_react2 from '../assets/images/portfolio/react/outsite.png';
import portfolio_react3 from '../assets/images/portfolio/react/playven.png';
import portfolio_react4 from '../assets/images/portfolio/react/financebaker.png';

import portfolio_vue1 from '../assets/images/portfolio/vue/propify.png';
import portfolio_vue2 from '../assets/images/portfolio/vue/shipbob.png';
import portfolio_vue3 from '../assets/images/portfolio/vue/portfolio.png';

import portfolio_ang1 from '../assets/images/portfolio/angular/selltime.png';
import portfolio_ang2 from '../assets/images/portfolio/angular/ezond.png';

import portfolio_node1 from '../assets/images/portfolio/node/selltime.png';
import portfolio_node2 from '../assets/images/portfolio/node/ezond.png';
import portfolio_node3 from '../assets/images/portfolio/node/outsite.png';
import portfolio_node4 from '../assets/images/portfolio/node/djangosoft.png';

import portfolio_lara1 from '../assets/images/portfolio/laravel/propify.png';
import portfolio_lara2 from '../assets/images/portfolio/laravel/ezond.png';
import portfolio_lara3 from '../assets/images/portfolio/laravel/konzeptsolutions.png';
import portfolio_lara4 from '../assets/images/portfolio/laravel/favoridecarrental.png';

 
const customStyles = {
  content : {
    width: '70%',
    top:'50%',
    left:'50%',
    right:'auto',
    bottom:'auto',
    marginRight:'-50%',
    transform:'translate(-50%, -50%)',
    padding:'0px',
    border: '0px',
    background: 'transparent'
  }
};

Modal.setAppElement('body');

function Portfolio() {
    const [modalIsOpen,setIsOpen] = React.useState(false);
    const [itemImage, setItemImage] = useState(null);
    const [itemLink, setItemLink] = useState('');
    function openModal(item) {
        console.log('item', item);
        setItemImage(item.portfolio);
        setItemLink(item.link);
        setIsOpen(true);
    }
    
    function closeModal(){
        setIsOpen(false);
    }

    const wordpress_list = [
        {id: 1, portfolio: portfolio_W1, link: 'http://cakaliko.kinsta.cloud/'}, 
        {id: 2, portfolio: portfolio_W2, link: 'https://isuniversity.org/'},
        {id: 3, portfolio: portfolio_W3, link: 'https://skymarkrefuelers.com'}, 
        {id: 4, portfolio: portfolio_W4, link: 'https://trescanorthend.com/'}, 
        {id: 5, portfolio: portfolio_W5, link: 'http://melroseyouthfootball.com/'}, 
        {id: 6, portfolio: portfolio_W6, link: 'https://www.lowellacademy.com/'}, 
    ];

    const cms_list = [
        {id: 1, portfolio: portfolio_cms1, link: 'https://www.spartasystems.com/'}, 
        {id: 2, portfolio: portfolio_cms2, link: 'https://content.smoworks.com/smo-icare-award-program'}, 
        {id: 3, portfolio: portfolio_cms3, link: 'https://www.wocnext.org/WOCN2020/Public/enter.aspx'},
        {id: 4, portfolio: portfolio_cms4, link: 'https://www.trihydro.com/'}, 
    ];

    const react_list = [
        {id: 1, portfolio: portfolio_react1, link: 'https://djangosoft.com/'},
        {id: 2, portfolio: portfolio_react2, link: 'https://www.outsite.co/'}, 
        {id: 3, portfolio: portfolio_react3, link: 'https://playven.com/'},
        {id: 4, portfolio: portfolio_react4, link: 'http://financebakerz.herokuapp.com/'},  
    ];

    const vue_list = [
        {id: 1, portfolio: portfolio_vue1, link: 'https://myhome.propify.app/'}, 
        {id: 2, portfolio: portfolio_vue2, link: 'https://www.shipbob.com/amazon-calculator/'},
        {id: 3, portfolio: portfolio_vue3, link: 'http://vue-my-portfolio.herokuapp.com/'}, 
    ];

    const angular_list = [
        {id: 1, portfolio: portfolio_ang1, link: 'https://portal.selltime.com'},
        {id: 2, portfolio: portfolio_ang2, link: 'https://ezond.com/'}, 
    ];

    const laravel_list = [
        {id: 1, portfolio: portfolio_lara1, link: 'https://myhome.propify.app/'}, 
        {id: 2, portfolio: portfolio_lara2, link: 'https://ezond.com/'}, 
        {id: 3, portfolio: portfolio_lara3, link: 'https://konzeptsolutions.com/'},
        {id: 4, portfolio: portfolio_lara4, link: 'http://demo.favoridecarrental.com/'}, 
    ];

    const node_list = [
        {id: 1, portfolio: portfolio_node1, link: 'https://portal.selltime.com'}, 
        {id: 2, portfolio: portfolio_node2, link: 'https://ezond.com/'}, 
        {id: 3, portfolio: portfolio_node3, link: 'https://www.outsite.co/'},
        {id: 4, portfolio: portfolio_node4, link: 'https://djangosoft.com/'}, 
    ];
    return (
        <section id="portfolio" className="portfolio">
            <div className="container">
                <div className="row">
                    <h2 className="section-heading">Our Portfolio...</h2>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="pixxett-tabs">
                            <div className="category-product">
                                <ul className="pixxett-tabs">
                                    <li className="active"><a data-toggle="tab" href="#wordpress">Wordpress</a></li>
                                    <li><a data-toggle="tab" href="#cms">CMS</a></li>
                                    <li><a data-toggle="tab" href="#react">React</a></li>
                                    <li><a data-toggle="tab" href="#vue">Vue</a></li>
                                    <li><a data-toggle="tab" href="#angular">Angular</a></li>
                                    <li><a data-toggle="tab" href="#node">Node</a></li>
                                    <li><a data-toggle="tab" href="#laravel">Laravel</a></li>
                                </ul>
                            </div>
                            <div className="tab-container">
                                <div className="tab-panel active" id="wordpress">
                                    {wordpress_list.map(item => {
                                        return (
                                            <div key={item.id} className="col-md-4 col-sm-6 col-xs-12 work php">
                                                <a href="#"> 
                                                    <img src={item.portfolio} alt=""/>
                                                    <div className="work-inner" onClick={() => openModal(item)}>
                                                        <div className="work-info"> <i className="fa fa-search"></i> </div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="tab-panel" id="cms">
                                    {cms_list.map(item => {
                                        return (
                                            <div key={item.id} className="col-md-4 col-sm-6 col-xs-12 work php">
                                                <a href="/#"> <img src={item.portfolio} alt=""/>
                                                    <div className="work-inner" onClick={() => openModal(item)}>
                                                        <div className="work-info"> <i className="fa fa-search"></i> </div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="tab-panel" id="react">
                                    {react_list.map(item => {
                                        return (
                                            <div key={item.id} className="col-md-4 col-sm-6 col-xs-12 work php">
                                                <a href="/#"> <img src={item.portfolio} alt=""/>
                                                    <div className="work-inner" onClick={() => openModal(item)}>
                                                        <div className="work-info"> <i className="fa fa-search"></i> </div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="tab-panel" id="vue">
                                    {vue_list.map(item => {
                                        return (
                                            <div key={item.id} className="col-md-4 col-sm-6 col-xs-12 work php">
                                                <a href="/#"> <img src={item.portfolio} alt=""/>
                                                    <div className="work-inner" onClick={() => openModal(item)}>
                                                        <div className="work-info"> <i className="fa fa-search"></i> </div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="tab-panel" id="angular">
                                    {angular_list.map(item => {
                                        return (
                                            <div key={item.id} className="col-md-4 col-sm-6 col-xs-12 work php">
                                                <a href="/#"> <img src={item.portfolio} alt=""/>
                                                    <div className="work-inner" onClick={() => openModal(item)}>
                                                        <div className="work-info"> <i className="fa fa-search"></i> </div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="tab-panel" id="node">
                                    {node_list.map(item => {
                                        return (
                                            <div key={item.id} className="col-md-4 col-sm-6 col-xs-12 work php">
                                                <a href="/#"> <img src={item.portfolio} alt=""/>
                                                    <div className="work-inner" onClick={() => openModal(item)}>
                                                        <div className="work-info"> <i className="fa fa-search"></i> </div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="tab-panel" id="laravel">
                                    {laravel_list.map(item => {
                                        return (
                                            <div key={item.id} className="col-md-4 col-sm-6 col-xs-12 work php">
                                                <a href="/#"> <img src={item.portfolio} alt=""/>
                                                    <div className="work-inner" onClick={() => openModal(item)}>
                                                        <div className="work-info"> <i className="fa fa-search"></i> </div>
                                                    </div>
                                                </a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {   
                setIsOpen && 
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="content">
                        <i className="fa fa-times" onClick={closeModal}></i>
                        <a href={itemLink} target="_blank" rel="noopener noreferrer">
                            <img src={itemImage} alt=""/>
                        </a>
                    </div>
                </Modal>
            }
        </section>
    );
}

export default Portfolio;